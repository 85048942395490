.welcome-section {
    position: relative;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f4f4f4;
  }
  
  .welcome-content {
    text-align: center;
  }
  
  .welcome-content h2 {
    font-size: 2.5rem;
    margin-bottom: 1rem;
  }
  
  .welcome-content p {
    font-size: 1.2rem;
    margin-bottom: 2rem;
  }
  
  .animated-words {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
  }
  
  .word {
    position: absolute;
    font-size: 1.5rem;
    color: rgba(0, 0, 0, 0.2);
    white-space: nowrap;
  }
  
 /* Estilos del modal */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  width: 80%;
  max-width: 500px;
  align-items: center;
  text-align: center;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.modal-content h3 {
  margin-bottom: 20px;
  font-size: 24px;
}

/* Imagen redonda */
.modal-image {
  width: 120px;
  height: 120px;
  
  border-radius: 50%;
  margin-bottom: 20px;
  object-fit: cover;
}

/* Estilo de los enlaces de contacto */
.contact-links {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 20px;
}

.contact-links a {
  font-size: 1.2rem;
  color:#4e4e4e;
  text-decoration: none;
  padding: 0.5rem 1rem;
  border: 1px solid #4e4e4e;
  border-radius: 5px;
  transition: background-color 0.3s, color 0.3s;
}

.contact-links a:hover {
  background-color: #4e4e4e;
  color: white;
}

.close-modal {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #4e4e4e;
  color:#ffffff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.close-modal:hover {
  background-color: #1d1d1d;
}

/* Añadir animación al modal */
.modal-overlay {
  animation: fadeInScale 0.4s ease-out forwards;
}

.modal-overlay.closing {
  animation: fadeOutScale 0.4s ease-out forwards;
}

/* Ajustes para pantallas más pequeñas */
@media (max-width: 768px) {
  .modal-content {
    width: 90%;
  }

  .contact-links a {
    font-size: 1rem;
  }
}

/* Animaciones */
@keyframes fadeInScale {
  0% {
    opacity: 0;
    transform: scale(0.7);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes fadeOutScale {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(0.7);
  }
}

@keyframes floatUp {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(-50px);
  }
}

/* Versión en modo oscuro */

/* Sección de bienvenida en modo oscuro */
body.dark-mode .welcome-section {
  background-color: #4e4e4e;
}

body.dark-mode .welcome-content h2 {
  color: #ffffff;
}

body.dark-mode .welcome-content p {
  color: #ffffff;
}

/* Palabras animadas en modo oscuro */
body.dark-mode .word {
  color: rgba(255, 255, 255, 0.2);
}

/* Modal en modo oscuro */
body.dark-mode .modal-overlay {
  background-color: rgba(0, 0, 0, 0.7);
}

body.dark-mode .modal-content {
  background-color: #333;
  color: #f4f4f4;
}

body.dark-mode .modal-content h3 {
  color: #fff;
}

body.dark-mode .modal-image {
  border: 2px solid #fff;
}

/* Enlaces de contacto en modo oscuro */
body.dark-mode .contact-links a {
  color: #f4f4f4;
  border-color: #f4f4f4;
}

body.dark-mode .contact-links a:hover {
  background-color: #f4f4f4;
  color: #333;
}

/* Botón de cerrar modal en modo oscuro */
body.dark-mode .close-modal {
  background-color: #555;
  color: #f4f4f4;
}

body.dark-mode .close-modal:hover {
  background-color: #777;
}
