/* Estilos generales del header */
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  background-color: #fff;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  transition: background-color 0.3s ease;
}

.dark-mode-header {
  background-color: #030303 !important; /* Cabecera en modo oscuro */
  color: #ddd !important;
}

.logo {
  font-size: 1.5rem;
  font-weight: bold;
}

.nav {
  display: flex;
  flex-grow: 1;
  justify-content: center;
}

.nav ul {
  display: flex;
  list-style-type: none;
  gap: 1rem;
  padding-left: 0;
  margin: 0;
}

.nav-link:hover {
  font-weight: bold; /* Negrita al hacer hover en modo oscuro */
}
/* Estilo hover en modo oscuro */
body.dark-mode .nav-link:hover {
  font-weight: bold; /* Negrita al hacer hover en modo oscuro */
}





/* Estilos del menú hamburguesa */
.hamburger {
  display: none;
  flex-direction: column;
  justify-content: space-between;
  width: 30px;
  height: 21px;
  cursor: pointer;
}

.bar {
  width: 100%;
  height: 3px;
  background-color: #333;
  transition: 0.3s;
}

.hamburger .bar.open:nth-child(1) {
  transform: rotate(45deg) translateY(8px);
}

.hamburger .bar.open:nth-child(2) {
  opacity: 0;
}

.hamburger .bar.open:nth-child(3) {
  transform: rotate(-45deg) translateY(-8px);
}

/* Estilos del menú en versión móvil */
.nav.open {
  position: absolute;
  top: 60px;
  left: 0;
  right: 0;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  gap: 1rem;
}

.nav ul {
  flex-direction: column;
  gap: 2rem;
}

.nav.open ul li {
  animation: fadeIn 0.5s ease-in-out forwards;
}




/* Botón de cambio de tema */
.theme-toggle {
  cursor: pointer;
  position: fixed;
  bottom: 20px;    
  right: 20px;    
  z-index: 1000;   
}

.toggle-button {
  width: 50px;
  height: 25px;
  border-radius: 25px;
  background-color: #ddd;
  position: relative;
  transition: background-color 0.3s ease;
}

.toggle-button.light {
  background-color: #ddd;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
}

.toggle-button.dark {
  background-color: #4d4d4d;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.toggle-circle {
  width: 23px;
  height: 23px;
  background-color: #fff;
  border-radius: 50%;
  position: absolute;
  top: 1px;
  left: 1px;
  transition: transform 0.3s ease;
}

.toggle-button.dark .toggle-circle {
  transform: translateX(25px);
}

/* Ajustes de responsividad en móviles */
@media (max-width: 768px) {
  .theme-toggle {
    right: 15px;
    bottom: 15px;
  }
}

/* Responsividad */
@media (max-width: 768px) {
  .nav {
    display: none;
  }

  .hamburger {
    display: flex;
  }

  .nav.open {
    display: flex;
  }
}

